import React from 'react';
import HeroPage from './components/HeroPage/HeroPage';
import AboutPage from './components/AboutPage/AboutPage';
import ContactPage from './components/ContactPage/ContactPage';





function App() {
  return (
    <>
      <HeroPage/>
      <AboutPage/>
      <ContactPage/>
    </>
  );
}

export default App;

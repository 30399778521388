import React, { useState } from 'react'

import image1 from '../../assets/images/biggreen.svg'
import image2 from '../../assets/images/tennis.svg'
import image3 from '../../assets/images/gameboy.svg'
import image4 from '../../assets/images/horse.svg'
import image5 from '../../assets/images/objects.svg'
import image6 from '../../assets/images/wurli.svg'
import image7 from '../../assets/images/blur.svg'
import image8 from '../../assets/images/couples.svg'
import image9 from '../../assets/images/lamps.svg'
import image10 from '../../assets/images/climber.svg'
import image11 from '../../assets/images/bigsur.svg'
import './Window.css'


function WindowSlideShow() {
    const [currentImage, setCurrentImage] = useState(0);
    let images = [
        image1,
        image2,
        image3,
        image4,
        image5,
        image6,
        image7,
        image8,
        image9,
        image10,
        image11
    ]

    const nextImage = () => {
        setCurrentImage((currentImage+1) % images.length);
    };

    setTimeout(nextImage, 1600);

    return (
    <>

    <div class="window-container">
        <img class="image" src={images[currentImage]} alt="image"/>
    </div>

    </>
    )
}


export default WindowSlideShow;

import "./AboutPage.css"


function AboutPage() {


    return (
        <>
        <div id="about-page" className="about-page-container">
            <div className="header-text">About<span className="period">.</span></div>

            <div className="body-text">We observe patterns and phenomena to find opportunities and help teams across various industries expand their reach internationally. By utilizing our distribution and marketing channels in foreign markets, Lofty-partnered companies can find value and thrive in today’s competitive landscape.</div>

        </div>

        </>
        )

}

export default AboutPage;

import Header from "./Header";

import "./HeroPage.css"
import WindowSlideShow from "./WindowSlideShow";


function HeroPage() {

    return (
        <>
        <div className="hero-page-container">
            <Header/>
            <WindowSlideShow/>
        </div>
        </>
        )

}


export default HeroPage;
